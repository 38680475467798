export interface UploadedFile {
  fileId: string;
  jobId?: string;
  index: number;
  fileName: string;
  status: JobStatus;
  conversionMode?: string;
  fileSize: number;
}

export interface ConvertedFile {
  fileId: string;
  jobId: string;
  fileName: string;
  convertedFileName: string;
  status: JobStatus;
  conversionMode?: string;
  fileSize: number;
  fileUrl: string;
}

export enum JobStatus {
  Complete = 0,
  Error = 1,
  Processing = 2,
  Wait = 3,
}

export interface ConvertedFileResponse {
  originalFileName: string;
  convertedFileName: string;
  jobId: string;
  fileUrl: string;
}

export interface FileData {
  fileId: string;
  data: File;
}

export interface JobStatusMessage {
  fileId: string;
  jobId: string;
  status: JobStatus;
  fileName: string;
  fileSize: number;
}

