import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {NgxFileDropEntry, NgxFileDropModule} from 'ngx-file-drop';
import {FilesService} from '../../../data/services/files.service';
import {JobStatus, UploadedFile} from '../../../models/converted-file';
import {Store} from "@ngrx/store";
import {addUploadedFile} from "../../../state/home.actions";
import {IndexedDbService} from "../../../data/services/indexed-db.service";

@Component({
  selector: 'app-file-box',
  standalone: true,
  imports: [
    NgxFileDropModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatButtonToggleModule,
    MatIconModule,
  ],
  templateUrl: './file-box.component.html',
  styleUrl: './file-box.component.scss',
})
export class FileBoxComponent {
  http = inject(HttpClient);
  indexedDbService = inject(IndexedDbService);
  filesService = inject(FilesService);
  store = inject(Store);
  public errorMessage: string = '';
  userAuth = localStorage.getItem('auth');
  private MAX_FILE_SIZE = 4 * 1024 * 1024;

  public dropped(files: NgxFileDropEntry[]) {
    if (this.userAuth) {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file(async (file: File) => {
            if (file.size > this.MAX_FILE_SIZE) {
              this.errorMessage = 'File is too large. Maximum file size is 4MB';
              return;
            }
            this.errorMessage = '';
            const fileCard: UploadedFile = {
              fileId: crypto.randomUUID(),
              fileName: file.name,
              index: fileEntry.file.length,
              status: JobStatus.Wait,
              fileSize: file.size,
              conversionMode: this.filesService.detectConversionMode(file.name),
            };
            if (fileCard.conversionMode === 'Unknown') {
              this.errorMessage = 'Invalid file format';
              return;
            } else {
              this.errorMessage = '';
              await this.indexedDbService.addOrUpdateFileData(fileCard.fileId, file);
              this.store.dispatch(addUploadedFile({file: fileCard}));
            }
          });
        }
      }
    } else {
      console.log('User not logged in');
    }
  }

  public fileOver(event: any) {
    console.log(event);
  }

  public fileLeave(event: any) {
    console.log(event);
  }
}
