import {Component, inject, Input} from '@angular/core';
import {JobStatus} from "../../../models/converted-file";
import {Store} from "@ngrx/store";
import {deleteConvertedFile} from "../../../state/home.actions";
import {FilesService} from "../../../data/services/files.service";

@Component({
  selector: 'app-converted-file-card',
  standalone: true,
  imports: [],
  templateUrl: './converted-file-card.component.html',
  styleUrl: './converted-file-card.component.scss'
})
export class ConvertedFileCardComponent {
  @Input() fileId: string = '';
  @Input() fileName: string = '';
  @Input() convertedFileName: string = '';
  @Input() fileSize: number = 0;
  @Input() jobId: string = '';
  @Input() status: JobStatus = JobStatus.Wait;
  @Input() fileUrl: string = '';
  store = inject(Store);
  fileService = inject(FilesService);
  protected readonly JobStatus = JobStatus;

  protected deleteConvertedFile() {
    this.fileService.deleteFileFromBucket(this.convertedFileName).subscribe(() => console.log('File deleted from bucket'));
    this.store.dispatch(deleteConvertedFile({fileId: this.fileId}));
  }
}
