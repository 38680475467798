import {provideHttpClient, withFetch} from '@angular/common/http';
import {ApplicationConfig, isDevMode} from '@angular/core';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideRouter} from '@angular/router';
import {provideEffects} from '@ngrx/effects';
import {provideStore} from '@ngrx/store';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {provideAuth} from 'angular-auth-oidc-client';
import {authOptions} from '../environments/environment';
import {routes} from './app.routes';
import {metaReducers, reducers} from './app.state';
import {AuthEffects} from './features/auth/state/auth.effects';
import {HomeEffects} from "./features/home/state/home.effects";
import {provideToastr} from "ngx-toastr";
import {provideAnimations} from "@angular/platform-browser/animations";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideAuth(authOptions),
    provideAnimationsAsync(),
    provideStore(reducers, {metaReducers}),
    provideEffects(AuthEffects, HomeEffects),
    provideStoreDevtools({maxAge: 25, logOnly: !isDevMode()}),
    provideToastr({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true
    }),
    provideAnimations()
  ],
};
