import {CommonModule} from '@angular/common';
import {Component, HostListener, inject, OnInit} from '@angular/core';
import {CdkDragDrop, DragDropModule} from '@angular/cdk/drag-drop';
import {select, Store} from '@ngrx/store';
import {loginButtonClicked, logoutButtonClicked, onInitLoginPage,} from '../../../auth/state/auth.actions';
import {selectAuthError, selectAuthUser,} from '../../../auth/state/auth.selectors';
import {FileBoxComponent} from '../components/file-box/file-box.component';
import {ProcessingFileCardComponent} from '../components/processing-file-card/processing-file-card.component';
import {ConvertedFile, ConvertedFileResponse, JobStatus, UploadedFile} from '../../models/converted-file';
import {environment} from '../../../../../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {selectConvertedFiles, selectUploadedFiles} from "../../state/home.selectors";
import {
  addConvertedFile,
  clearFileQueue,
  conversionComplete,
  loadUploadedFiles,
  reorderUploadedFiles,
  resetFileStatus,
  updateUploadedFileStatus
} from "../../state/home.actions";
import {BehaviorSubject, catchError, combineLatest, from, map, mergeMap, Observable, of, Subject, take} from "rxjs";
import {IndexedDbService} from "../../data/services/indexed-db.service";
import {SignalRService} from "../../data/services/signal-r.service";
import {ConvertedFileCardComponent} from "../components/converted-file-card/converted-file-card.component";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FileBoxComponent,
    ProcessingFileCardComponent,
    CommonModule,
    DragDropModule,
    HttpClientModule,
    ConvertedFileCardComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})

export class HomeComponent implements OnInit {
  http = inject(HttpClient);
  signalr = inject(SignalRService);
  indexedDbService = inject(IndexedDbService);
  userAuth = localStorage.getItem('auth');
  isFileBoxBlocked = false;
  combinedFiles$: Observable<(UploadedFile | ConvertedFile)[]>;
  protected isDropdownMenuOpen = false;
  private toastr = inject(ToastrService);
  private baseUrl = environment.apiUrl;
  private store = inject(Store);
  protected user$ = this.store.select(selectAuthUser);
  protected error$ = this.store.select(selectAuthError);
  protected uploadedFiles$ = this.store.select(selectUploadedFiles).pipe(
    map(files => files.filter(file => file.fileName !== undefined))
  );
  protected convertedFiles$ = this.store.select(selectConvertedFiles);
  private completedRequests = 0;
  private currentRequest: any;

  constructor() {
    this.uploadedFiles$ = this.store.pipe(select(selectUploadedFiles));
    this.combinedFiles$ = combineLatest([this.uploadedFiles$, this.convertedFiles$]).pipe(
      map(([uploadedFiles, convertedFiles]) => [...uploadedFiles, ...convertedFiles])
    );
  }

  lockFileBox() {
    this.isFileBoxBlocked = true;
  }

  unlockFileBox() {
    this.isFileBoxBlocked = false;
  }

  ngOnInit(): void {
    this.store.dispatch(onInitLoginPage());
    this.store.dispatch(loadUploadedFiles());
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown')) {
      this.isDropdownMenuOpen = false;
    }
  }

  cancelQueue() {
    if (this.currentRequest) {
      this.currentRequest.unsubscribe();
      this.unlockFileBox();
      this.store.dispatch(resetFileStatus());
    }
  }

  clearQueue() {
    this.store.dispatch(clearFileQueue());
  }

  sendQueue() {
    this.lockFileBox();
    const userId = this.getUserId();
    const cancelProcessing$ = new BehaviorSubject<boolean>(false);

    const fileQueue = new Subject<() => Promise<void>>();

    fileQueue
      .pipe(
        mergeMap((processFile) =>
            from(processFile()).pipe(
              catchError((error) => {
                console.error("Erro ao processar arquivo:", error);
                return of(null);
              })
            ),
          1
        )
      )
      .subscribe({
        complete: () => {
          this.unlockFileBox();
          console.log("All files processed.");
        },
      });

    this.uploadedFiles$.pipe(take(1)).subscribe((fileCard) => {
      fileCard.forEach((file) => {
        fileQueue.next(async () => {
          if (cancelProcessing$.value) {
            return;
          }
          try {
            const fileData = await this.indexedDbService.getFileData(file.fileId);
            const formData = new FormData();
            formData.append("fileData", fileData.data, file.fileName);

            await new Promise<void>((resolve) => {
              this.currentRequest = this.http
                .post<ConvertedFileResponse>(
                  `${this.baseUrl}api/ConversionJob/convert-file?fileId=${file.fileId}&userId=${userId}`,
                  formData
                )
                .pipe(
                  catchError((error) => {
                    this.store.dispatch(
                      updateUploadedFileStatus({fileId: file.fileId, status: JobStatus.Error})
                    );
                    console.error(`Erro ao processar o arquivo ${file.fileId}:`, error);
                    this.toastr.error(`An error occurred while processing the file ${file.fileName}.`);
                    resolve();
                    return of(null);
                  })
                )
                .subscribe((data) => {
                  if (!data) {
                    resolve();
                    return;
                  }

                  this.store.dispatch(
                    updateUploadedFileStatus({fileId: file.fileId, status: JobStatus.Complete})
                  );
                  const convertedFile: ConvertedFile = {
                    fileId: file.fileId,
                    status: JobStatus.Complete,
                    fileSize: file.fileSize,
                    fileName: data.originalFileName,
                    convertedFileName: data.convertedFileName,
                    jobId: data.jobId,
                    fileUrl: data.fileUrl,
                  };
                  this.store.dispatch(addConvertedFile({file: convertedFile}));
                  this.store.dispatch(conversionComplete({fileId: file.fileId}));
                  this.handleRequestCompletion(fileCard);
                  resolve();
                });
            });
          } catch (error) {
            console.error(`Erro ao acessar IndexedDB para o arquivo ${file.fileId}:`, error);
            this.store.dispatch(
              updateUploadedFileStatus({fileId: file.fileId, status: JobStatus.Error})
            );
          }
        });
      });
      fileQueue.complete();
    });
  }


  protected toggleDropdownMenu(event: Event) {
    event.stopPropagation();
    this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
  }

  protected loginButtonClick() {
    this.store.dispatch(loginButtonClicked());
  }

  protected logoutButtonClick() {
    this.store.dispatch(logoutButtonClicked());
  }

  protected drop(event: CdkDragDrop<UploadedFile[]>) {
    if (event.previousIndex !== event.currentIndex) {
      this.store.dispatch(reorderUploadedFiles({previousIndex: event.previousIndex, currentIndex: event.currentIndex}));
    }
  }

  protected getUserId() {
    if (this.userAuth) {
      const authObject = JSON.parse(this.userAuth);
      const userId = authObject.user.sub;
      console.log(userId);
      return userId;
    }
    return null;
  }

  protected handleRequestCompletion(files: UploadedFile[]) {
    this.completedRequests++;
    if (this.completedRequests === files.length) {
      this.unlockFileBox();
      this.completedRequests = 0;
    }
  };
}

